import React, { Suspense, lazy } from "react";
import { Navigate } from "react-router-dom";
import useCustomLogin from "../../hooks/useCustomLogin";

const Loading = () => (
  <div className="flex justify-center items-center h-screen w-full">
    <div className="animate-spin border-t-2 border-b-2 border-blue-500 rounded-full w-8 h-8"></div>
    <span className="ml-2">Loading...</span>
  </div>
);

// Lazy loaded components
const Dashboard = lazy(() =>
  import("../../pages/app/performance/dashboard/DashboardPage")
);
const Goal = lazy(() => import("../../pages/app/performance/goal/GoalPage"));
const GoalOverview = lazy(() =>
  import("../../pages/app/performance/goal/GoalOverview")
);
const GoalRequest = lazy(() =>
  import("../../pages/app/performance/goal/GoalRequests")
);
const Review = lazy(() =>
  import("../../pages/app/performance/review/ReviewPage")
);
const DashboardTeam = lazy(() =>
  import("../../pages/app/performance/dashboard/DashboardTeam")
);
const DashboardMy = lazy(() =>
  import("../../pages/app/performance/dashboard/DashboardMy")
);
const DashboardAll = lazy(() =>
  import("../../pages/app/performance/dashboard/DashboardAll")
);
const GoalRequestProgress = lazy(() =>
  import("../../pages/app/performance/goal/GoalRequestProgress")
);
const GoalRequestCompleted = lazy(() =>
  import("../../pages/app/performance/goal/GoalRequestCompleted")
);
const GoalRequestPending = lazy(() =>
  import("../../pages/app/performance/goal/GoalRequestPending")
);
const GoalMy = lazy(() => import("../../pages/app/performance/goal/GoalMy"));
const GoalTeam = lazy(() =>
  import("../../pages/app/performance/goal/GoalTeam")
);
const GoalAll = lazy(() => import("../../pages/app/performance/goal/GoalAll"));
const GoalRequestCompletedMember = lazy(() =>
  import("../../pages/app/performance/goal/GoalRequestCompletedMember")
);
const GoalRequestCompletedLeader = lazy(() =>
  import("../../pages/app/performance/goal/GoalRequestCompletedLeader")
);
const GoalRequestCompletedAccount = lazy(() =>
  import("../../pages/app/performance/goal/GoalRequestCompletedAccount")
);

// 기본 Route Protection 컴포넌트
const ProtectedRoute = ({ children }) => {
  const { loginInfo } = useCustomLogin();

  if (!loginInfo) {
    return <Navigate to="/login" replace />;
  }

  return children;
};

const performanceRouter = () => {
  return [
    {
      path: "",
      element: <Navigate to="/app/performance/dashboard" replace />,
    },
    {
      path: "dashboard",
      element: (
        <ProtectedRoute>
          <Suspense fallback={<Loading />}>
            <Dashboard />
          </Suspense>
        </ProtectedRoute>
      ),
      children: [
        // {
        //     path: '',
        //     element: <Navigate to="my" replace />
        // },
        {
          path: "my",
          element: (
            <ProtectedRoute>
              <Suspense fallback={<Loading />}>
                <DashboardMy />
              </Suspense>
            </ProtectedRoute>
          ),
        },
        {
          path: "team",
          element: (
            <ProtectedRoute>
              <Suspense fallback={<Loading />}>
                <DashboardTeam />
              </Suspense>
            </ProtectedRoute>
          ),
        },
        {
          path: "all",
          element: (
            <ProtectedRoute>
              <Suspense fallback={<Loading />}>
                <DashboardAll />
              </Suspense>
            </ProtectedRoute>
          ),
        },
      ],
    },
    {
      path: "goal",
      element: (
        <ProtectedRoute>
          <Suspense fallback={<Loading />}>
            <Goal />
          </Suspense>
        </ProtectedRoute>
      ),
      children: [
        {
          path: "",
          element: <Navigate to="overview" replace />,
        },
        {
          path: "overview",
          element: (
            <ProtectedRoute>
              <Suspense fallback={<Loading />}>
                <GoalOverview />
              </Suspense>
            </ProtectedRoute>
          ),
          children: [
            {
              path: "",
              element: <Navigate to="my" replace />,
            },
            {
              path: "my",
              element: (
                <ProtectedRoute>
                  <Suspense fallback={<Loading />}>
                    <GoalMy />
                  </Suspense>
                </ProtectedRoute>
              ),
            },
            {
              path: "team",
              element: (
                <ProtectedRoute>
                  <Suspense fallback={<Loading />}>
                    <GoalTeam />
                  </Suspense>
                </ProtectedRoute>
              ),
            },
            {
              path: "all",
              element: (
                <ProtectedRoute>
                  <Suspense fallback={<Loading />}>
                    <GoalAll />
                  </Suspense>
                </ProtectedRoute>
              ),
            },
          ],
        },
        {
          path: "request",
          element: (
            <ProtectedRoute>
              <Suspense fallback={<Loading />}>
                <GoalRequest />
              </Suspense>
            </ProtectedRoute>
          ),
          children: [
            // {
            //     path: '',
            //     element: <Navigate to="progress" replace />
            // },
            {
              path: "progress",
              element: (
                <ProtectedRoute>
                  <Suspense fallback={<Loading />}>
                    <GoalRequestProgress />
                  </Suspense>
                </ProtectedRoute>
              ),
            },
            {
              path: "pending",
              element: (
                <ProtectedRoute>
                  <Suspense fallback={<Loading />}>
                    <GoalRequestPending />
                  </Suspense>
                </ProtectedRoute>
              ),
            },
            {
              path: "completed/member",
              element: (
                <ProtectedRoute>
                  <Suspense fallback={<Loading />}>
                    <GoalRequestCompletedMember />
                  </Suspense>
                </ProtectedRoute>
              ),
            },
            {
              path: "completed/leader",
              element: (
                <ProtectedRoute>
                  <Suspense fallback={<Loading />}>
                    <GoalRequestCompletedLeader />
                  </Suspense>
                </ProtectedRoute>
              ),
            },
            {
              path: "completed/account",
              element: (
                <ProtectedRoute>
                  <Suspense fallback={<Loading />}>
                    <GoalRequestCompletedAccount />
                  </Suspense>
                </ProtectedRoute>
              ),
            },
          ],
        },
      ],
    },
    {
      path: "review",
      element: (
        <ProtectedRoute>
          <Suspense fallback={<Loading />}>
            <Review />
          </Suspense>
        </ProtectedRoute>
      ),
    },
  ];
};

export default performanceRouter;
