import React, { Suspense, lazy } from "react";
import { Navigate } from "react-router-dom";
import useCustomLogin from "../../hooks/useCustomLogin";

const Loading = () => (
  <div className="flex justify-center items-center h-screen w-full">
    <div className="animate-spin border-t-2 border-b-2 border-blue-500 rounded-full w-8 h-8"></div>
    <span className="ml-2">Loading...</span>
  </div>
);

const Organization = lazy(() => import("../../pages/admin/common/OrganizationPage"));
const Position = lazy(() => import("../../pages/admin/common/PositionPage"));
const Title = lazy(() => import("../../pages/admin/common/TitlePage"));
const Member = lazy(() => import("../../pages/admin/common/MemberPage"));
const Account = lazy(() => import("../../pages/admin/common/AccountPage"));
const Company = lazy(() => import("../../pages/admin/common/CompanyPage"));

// 접근 권한 체크를 위한 컴포넌트
const RoleProtectedRoute = ({ allowedRoles, children }) => {
  const { loginInfo } = useCustomLogin();

  if (
    !loginInfo ||
    !loginInfo.roleNames.some((role) => allowedRoles.includes(role))
  ) {
    return <Navigate to="/admin/common/dashboard" replace />;
  }

  return children;
};

const commonRouter = () => {
  return [
    {
      path: "",
      element: <Navigate to="/admin/common/organization" replace />,
    },
    {
      path: "organization",
      element: (
        <Suspense fallback={<Loading />}>
          <Organization />
        </Suspense>
      ),
    },
    {
      path: "position",
      element: (
        <Suspense fallback={<Loading />}>
          <Position />
        </Suspense>
      ),
    },
    {
      path: "title",
      element: (
        <Suspense fallback={<Loading />}>
          <Title />
        </Suspense>
      ),
    },
    {
      path: "member",
      element: (
        <Suspense fallback={<Loading />}>
          <Member />
        </Suspense>
      ),
    },
    {
      path: "account",
      element: (
        <RoleProtectedRoute allowedRoles={["SUPER", "ADMIN"]}>
          <Suspense fallback={<Loading />}>
            <Account />
          </Suspense>
        </RoleProtectedRoute>
      ),
    },
    {
      path: "company",
      element: (
        <Suspense fallback={<Loading />}>
          <Company />
        </Suspense>
      ),
    },
  ];
};

export default commonRouter;
