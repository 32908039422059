import React, { Suspense, lazy } from "react";
import { Navigate } from "react-router-dom";

const Loading = () => (
    <div className="flex justify-center items-center h-screen w-full">
        <div className="animate-spin border-t-2 border-b-2 border-blue-500 rounded-full w-8 h-8"></div>
        <span className="ml-2">Loading...</span>
    </div>
);

const Dashboard = lazy(() => import("../../pages/admin/performance/DashboardPage"));
const Card = lazy(() => import("../../pages/admin/performance/CardPage"));
const Template = lazy(() => import("../../pages/admin/performance/TemplatePage"));

const performanceRouter = () => {
    return [
        {
            path: '',
            element: <Navigate to="/admin/performance/dashboard" replace />
        },
        {
            path: 'dashboard',
            element: <Suspense fallback={<Loading />}><Dashboard/></Suspense>
        },
        {
            path: 'card',
            element: <Suspense fallback={<Loading />}><Card/></Suspense>
        },
        {
            path: 'template',
            element: <Suspense fallback={<Loading />}><Template/></Suspense>
        }
    ];
}

export default performanceRouter;