import axios from "axios";

let accessToken = null; // 메모리에서 관리할 accessToken

const jwtAxios = axios.create(); // jwtAxios 인스턴스를 생성합니다

const handleTokenError = () => {
  console.log("Refresh token expired. Redirecting to login page...");
  accessToken = null;
  window.location.replace("/login");
  return false;
};

const refreshJWT = async () => {
  const apiHost = process.env.REACT_APP_API_HOST;

  const header = {
    withCredentials: true,  // 쿠키에 있는 리프레시 토큰을 전송하기 위해 필요
  };

  try {
    const res = await axios.post(`${apiHost}/api/auth/refresh`, null, header);

    if (res.data && res.data.error) {
      return handleTokenError(); // refresh Token 에러 처리 함수 호출
    }

    accessToken = res.data.accessToken; // 메모리에 새로 받은 Access Token 저장
    return accessToken;
  } catch (err) {
    if (err.response && err.response.status === 400) {
      return handleTokenError(); // 에러 처리 함수 호출
    }
    console.error("Error refreshing JWT", err);
    throw err;
  }
};

// Set the access token in memory
function setAccessToken(token) {
  accessToken = token;
}

//before request
const beforeReq = (config) => {
  console.log("before request.............");
  if (!accessToken) {
    console.log("Access Token NOT FOUND");
    return Promise.reject({ response: { data: { error: "REQUIRE_LOGIN" } } });
  }
  config.headers.Authorization = `Bearer ${accessToken}`;
  return config;
};

//fail request
const requestFail = (err) => {
  console.log("request error............");
  return Promise.reject(err);
};

//before return response
const beforeRes = async (res) => {
  console.log("before return response...........");

  const data = res.data;
  if (data && data.error === "ERROR_ACCESS_TOKEN") {
    if (!accessToken) {
      console.log("Access Token NOT FOUND");
      return Promise.reject({ response: { data: { error: "REQUIRE_LOGIN" } } });
    }

    const result = await refreshJWT();
    console.log("refreshJWT RESULT", result);

    if (!result) {
      return false;
    }

    // 원래의 요청을 새로운 accessToken으로 재시도
    const originalRequest = res.config;
    originalRequest.headers.Authorization = `Bearer ${accessToken}`;
    return await axios(originalRequest);
  }
  return res;
};

//fail response
const responseFail = (err) => {
  console.log("response fail error.............");
  return Promise.reject(err);
};

jwtAxios.interceptors.request.use(beforeReq, requestFail); // jwtAxios 인스턴스 사용
jwtAxios.interceptors.response.use(beforeRes, responseFail);

export default jwtAxios; // jwtAxios를 default로 export

export { accessToken, setAccessToken, refreshJWT };