import React from 'react';
import { RouterProvider } from 'react-router-dom';
import { ToastProvider } from './context/ToastContext';
import root from './router/root';

export default function App() {
  return (
    <ToastProvider>
      <RouterProvider future={({ v7_startTransition: true })} router={root} />
    </ToastProvider>
  );
}