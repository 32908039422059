import React, { Suspense, lazy } from "react";
import { Navigate } from "react-router-dom";
import commonRouter from "./app/commonRouter";
import performanceRouter from "./app/performanceRouter";

const Loading = () => (
    <div className="flex justify-center items-center h-screen w-full">
        <div className="animate-spin border-t-2 border-b-2 border-blue-500 rounded-full w-8 h-8"></div>
        <span className="ml-2">Loading...</span>
    </div>
);

const CommonIndex = lazy(() => import("../pages/app/common/CommonIndex"));
const PerformanceIndex = lazy(() => import("../pages/app/performance/PerformanceIndex"));

const appRouter = () => {
    return [
        {
            path: '',
            element: <Navigate to="/app/performance/dashboard" replace />
        },
        {
            path: 'common',
            element: <Suspense fallback={<Loading />}><CommonIndex /></Suspense>,
            children: commonRouter(),
        },
        {
            
            path: 'performance',
            element: <Suspense fallback={<Loading />}><PerformanceIndex /></Suspense>,
            children: performanceRouter(),
        },
    ];
}

export default appRouter;