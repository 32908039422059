import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { initLoginPost, loginPost, logoutPost } from "../api/auth/authApi"; // logoutPost 추가
import { setAccessToken } from "../util/jwtUtil";
import { setPublicKey } from "../util/publicKey";

const parseJWT = (token) => {
  try {
    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    const jsonPayload = decodeURIComponent(atob(base64).split('').map(function(c) {
      return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));

    return JSON.parse(jsonPayload);
  } catch (error) {
    console.error("Invalid Token", error);
    return null;
  }
};

const getLoginInfoFromToken = (token) => {
  return parseJWT(token);
};

// Initial state
const initState = {
  accessToken: null,
  loginInfo: null, // Store the whole loginInfo object
};

// 로그인페이지 초기화 비동기 호출
export const initLoginPostAsync = createAsyncThunk("initLoginPostAsync", async (_, thunkAPI) => {
  const response = await initLoginPost();
  if (response.publicKey) {
    setPublicKey(response.publicKey);
    thunkAPI.dispatch(initLogin());
  }
});

// 로그인 비동기 호출
export const loginPostAsync = createAsyncThunk("loginPostAsync", async (param, thunkAPI) => {
  const response = await loginPost(param);
  if (response.accessToken) {
    const loginInfo = getLoginInfoFromToken(response.accessToken);
    thunkAPI.dispatch(setLoginInfo(loginInfo));  // Dispatch action to set loginInfo
    setAccessToken(response.accessToken); // 메모리에 accessToken 설정
  }
  return response;
});

// 로그아웃 비동기 호출
export const logoutPostAsync = createAsyncThunk("logoutPostAsync", async (_, thunkAPI) => {
  await logoutPost(); // API를 통해 로그아웃 처리
  thunkAPI.dispatch(logout()); // 상태를 초기화
});

const loginSlice = createSlice({
  name: "LoginSlice",
  initialState: initState,
  reducers: {
    initLogin: (state) => {
      setAccessToken(null);
      return { ...initState };
    },
    login: (state, action) => {
      const loginInfo = getLoginInfoFromToken(action.payload.accessToken);
      setAccessToken(action.payload.accessToken);
      return { accessToken: action.payload.accessToken, loginInfo };
    },
    logout: (state) => {
      setAccessToken(null); // 메모리에서 accessToken 삭제
      return { ...initState }; // 상태 초기화
    },
    setLoginInfo: (state, action) => {
      state.loginInfo = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(initLoginPostAsync.fulfilled, (state) => {
        console.log("InitLogin successful");
      })
      .addCase(initLoginPostAsync.rejected, (state) => {
        console.error("InitLogin failed");
      })
      .addCase(loginPostAsync.fulfilled, (state, action) => {
        const accessToken = action.payload.accessToken;
        if (accessToken) {
          setAccessToken(accessToken);
          state.accessToken = accessToken;
        }
      })
      .addCase(loginPostAsync.pending, (state) => {
        console.log("pending");
      })
      .addCase(loginPostAsync.rejected, (state) => {
        console.log("rejected");
      })
      .addCase(logoutPostAsync.fulfilled, (state) => {
        console.log("Logout successful");
      })
      .addCase(logoutPostAsync.rejected, (state) => {
        console.error("Logout failed");
      });
  },
});

export const { initLogin, login, logout, setLoginInfo } = loginSlice.actions;
export default loginSlice.reducer;