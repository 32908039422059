import { useDispatch, useSelector } from "react-redux";
import { Navigate, useNavigate } from "react-router-dom";
import {
  initLoginPostAsync,
  loginPostAsync,
  logoutPostAsync,
  login,
} from "../slices/loginSlice";
import { setAccessToken, refreshJWT } from "../util/jwtUtil";

const useCustomLogin = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const loginState = useSelector((state) => state.loginSlice);
  const isLogin = !!loginState.accessToken; // Access Token 존재 여부로 로그인 상태 확인
  const loginInfo = loginState.loginInfo;

  // 로그인 페이지 초기화 함수
  const initLogin = async () => {
    try {
      await dispatch(initLoginPostAsync()); // 백엔드 로그아웃 호출 및 Redux 상태 초기화
      setAccessToken(null); // 메모리에서 토큰 제거
    } catch (error) {
      console.error("InitLogin failed", error);
    }
  };

  // 로그인 처리 함수
  const doLogin = async (loginParam) => {
    const action = await dispatch(loginPostAsync(loginParam));
    if (action.payload.accessToken) {
      setAccessToken(action.payload.accessToken); // 메모리에 토큰 저장
    }
    return action.payload;
  };

  // 로그아웃 처리 함수
  const doLogout = async () => {
    try {
      await dispatch(logoutPostAsync()); // 백엔드 로그아웃 호출 및 Redux 상태 초기화
      setAccessToken(null); // 메모리에서 토큰 제거
      navigate("/login", { replace: true }); // 로그아웃 후 로그인 페이지로 이동
    } catch (error) {
      console.error("Logout failed", error);
    }
  };

  // 리프레시 토큰으로 엑세스 토큰 갱신 함수
  const refreshToken = async () => {
    try {
      const newToken = await refreshJWT();
      if (newToken) {
        dispatch(login({ accessToken: newToken })); // Redux 상태 업데이트
        setAccessToken(newToken); // 메모리에 새 토큰 설정
        return true;
      }
    } catch (error) {
      console.error("Failed to refresh token", error);
      doLogout(); // 갱신 실패 시 로그아웃
    }
    return false;
  };

  // 특정 경로로 이동 함수
  const moveToPath = (path) => {
    navigate(path, { replace: true });
  };

  // 로그인 페이지로 이동 함수
  const moveToLogin = () => {
    navigate("/login", { replace: true });
  };

  // 리다이렉트와 함께 로그인 페이지로 이동하는 함수
  const moveToLoginReturn = () => {
    return <Navigate replace to="/login" />;
  };

  return {
    isLogin,
    loginInfo,
    initLogin,
    doLogin,
    doLogout,
    refreshToken, // 리프레시 토큰을 통한 토큰 갱신 기능
    moveToPath, // 경로 이동 기능
    moveToLogin, // 로그인 페이지로 이동 기능
    moveToLoginReturn, // 리다이렉트와 함께 로그인 페이지로 이동 기능
  };
};

export default useCustomLogin;
