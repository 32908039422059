import axios from "axios"; // axios import 추가
import qs from "qs";

const apiHost = process.env.REACT_APP_API_HOST;

// 로그인 초기화 API
export const initLoginPost = async () => {
  try {
    const res = await axios.post(
      `${apiHost}/api/auth/initLogin`,
      {},
      {
        withCredentials: true,
      }
    );
    console.log("initLogin successfully");
    return res.data;
  } catch (error) {
    console.error("initLogin failed", error);
  }
};

// 로그인 API
export const loginPost = async (loginParam) => {
  // withCredentials 설정 추가
  const res = await axios.post(
    `${apiHost}/api/auth/login`,
    qs.stringify({
      username: loginParam.username,
      password: loginParam.password,
    }),
    {
      withCredentials: true, // 쿠키를 포함하여 요청을 보냄
      headers: {
        "Content-Type": "application/x-www-form-urlencoded", // 파라미터 전송을 위한 헤더 설정
      },
    }
  );

  return res.data;
};

// 로그아웃 API
export const logoutPost = async () => {
  try {
    await axios.post(
      `${apiHost}/api/auth/logout`,
      {},
      {
        withCredentials: true, // refreshToken 쿠키 포함
      }
    );
    console.log("Logged out successfully");
  } catch (error) {
    console.error("Logout failed", error);
  }
};
