import React, { Suspense, lazy } from "react";
import { Navigate } from "react-router-dom";
import commonRouter from "./admin/commonRouter";
import performanceRouter from "./admin/performanceRouter";
import auditRouter from "./admin/auditRouter";

const Loading = () => (
    <div className="flex justify-center items-center h-screen w-full">
        <div className="animate-spin border-t-2 border-b-2 border-blue-500 rounded-full w-8 h-8"></div>
        <span className="ml-2">Loading...</span>
    </div>
);

const CommonIndex = lazy(() => import("../pages/admin/common/CommonIndex"));
const PerformanceIndex = lazy(() => import("../pages/admin/performance/PerformanceIndex"));
const AuditIndex = lazy(() => import("../pages/admin/audit/AuditIndex"));

const adminRouter = () => {
    return [
        {
            path: '',
            element: <Navigate to="/admin/performance/dashboard" replace />
        },
        {
            path: 'common',
            element: <Suspense fallback={<Loading />}><CommonIndex /></Suspense>,
            children: commonRouter(),
        },
        {
            path: 'performance',
            element: <Suspense fallback={<Loading />}><PerformanceIndex /></Suspense>,
            children: performanceRouter(),
        },
        {
            path: 'audit',
            element: <Suspense fallback={<Loading />}><AuditIndex /></Suspense>,
            children: auditRouter(),
        }

    ];
}

export default adminRouter;