import React, { Suspense, lazy } from "react";
import { Navigate } from "react-router-dom";

const Loading = () => (
    <div className="flex justify-center items-center h-screen w-full">
        <div className="animate-spin border-t-2 border-b-2 border-blue-500 rounded-full w-8 h-8"></div>
        <span className="ml-2">Loading...</span>
    </div>
);

const Profile = lazy(() => import("../../pages/app/common/ProfilePage"));
const Member = lazy(() => import("../../pages/app/common/MemberPage"));

const commonRouter = () => {
    return [
        {
            path: '',
            element: <Navigate to="/app/common/profile" replace />
        },
        {
            path: 'profile',
            element: <Suspense fallback={<Loading />}><Profile /></Suspense>
        },
        {
            path: 'member',
            element: <Suspense fallback={<Loading />}><Member /></Suspense>
        },
    ];
}

export default commonRouter;