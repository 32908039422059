import React, { Suspense, lazy } from "react";
import { Navigate } from "react-router-dom";

const Loading = () => (
    <div className="flex justify-center items-center h-screen w-full">
        <div className="animate-spin border-t-2 border-b-2 border-blue-500 rounded-full w-8 h-8"></div>
        <span className="ml-2">Loading...</span>
    </div>
);

const Auth = lazy(() => import("../../pages/admin/audit/AuthPage"));
const Activity = lazy(() => import("../../pages/admin/audit/ActivityPage"));

const auditRouter = () => {
    return [
        {
            path: '',
            element: <Navigate to="/admin/audit/auth" replace />
        },
        {
            path: 'auth',
            element: <Suspense fallback={<Loading />}><Auth/></Suspense>
        },
        {
            path: 'activity',
            element: <Suspense fallback={<Loading />}><Activity/></Suspense>
        }
    ];
}

export default auditRouter;